<template>
  <div>
    <div class="page-title" v-show="user">{{ user }}，欢迎回来。</div>
    <div style="float:left;width:100%;margin-top:1rem">
      <el-button @click="LoginOut" plain type="danger">安全退出</el-button>
    </div>
  </div>
</template>

<script>
import {baseTitle} from '../configs/squareConfig'
import {getUserName} from '../http/api'

export default {
  name: "Welcome",
  data() {
    return {
      user: '',
    }
  },
  methods: {
    LoginOut() {
      this.$confirm('确认退出登录？')
        .then(res => {
          this.$cookies.remove('token')
          this.$router.push('/monitor')
        })
    },
  },
  activated() {
    document.title = '欢迎屏幕 - ' + baseTitle
    const token = this.$cookies.get('token')
    getUserName({token})
      .then(res => {
        if (res.data.code === 'OK') {
          this.user = res.data.user
        }
      })
      .catch(err => console.log(err))
  },
}
</script>

<style scoped>

</style>
